* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

:root {
    --primary-color: #fff;
    --secondary-color: #000;
    --tertiary-color: rgba(255, 0, 70, 1);
    --quaternary-color: rgba(40, 40, 50, 1);
    --quinary-color: rgba(10, 10, 20, 1);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

body,
#root,
html {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--primary-color);
}

.pageContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 1200px;
    z-index: 1;
}
/* 
header {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

.logoContainer {
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.logo {
    height: 100%;
    fill: rgba(255, 0, 70, 1);
}

.titleText {
    font-size: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 20px;
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--secondary-color);
}

.subTitleText {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 20px;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
    position: absolute;
    padding-left: 240px;
    padding-top: 5px;
    color: var(--secondary-color);
}

.pageContent {
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    overflow-y: auto;
    flex-direction: column;
}

.navBar {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding-block: 40px;
}

.navBar svg:last-child {
    margin-right: 0;
}

.navLogo {
    height: 100%;
    margin-inline: 10px;
    cursor: pointer;
    fill: var(--secondary-color);
}

.keySaveButton:active {
    background-color: rgba(255, 0, 50, 1);
}

.themeContainer {
    width: 40px;
    height: 100%;
    border-radius: 12px;
    position: relative;
    padding: 4px;
}

.themeLogo {
    height: 100%;
    fill: var(--secondary-color);
    cursor: pointer;
}

.resetApiKeyButton {
    width: 100px;
    height: 40px;
    background-color: var(--quaternary-color);
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    color: var(--secondary-color);
    margin-right: 20px;
}

.languageDropdownContainer {
    position: relative;
}

.languageDropdown {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 100;
    position: absolute;
    left: -60px;
    overflow-y: auto;
    max-height: 300px;
    margin-top: 10px;
    background-color: var(--quinary-color);
}

.languageSelectButton {
    background-color: var(--quinary-color);
    border: none;
    border-bottom: 0.5px solid var(--secondary-color);
    width: 160px;
    height: 40px;
    color: var(--secondary-color);
}

.languageSelectButton:hover .languageSelectText{
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.languageSelectText {
    color: var(--secondary-color);
    display: flex;
    align-items: center;    
    justify-content: center;
    font-size: 1rem;
    font-weight: 300;
    width: 100%;
    padding-block: 10px;
}

.languageButton {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    border: none;
    margin-right: 20px;
    background-color: var(--quaternary-color);
    cursor: pointer;
    font-size: 1.5rem;
}

.privacyTitle {
    width: 100%;
    height: 60px;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    color: var(--secondary-color);
}

.privacyHeading {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    margin-block: 20px;
    color: var(--secondary-color);
}

.privacyPoint {
    width: 100%;
    margin-bottom: 5px;
    font-weight: 300;
    color: var(--secondary-color);
}