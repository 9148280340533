@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

* {
    overscroll-behavior-y: none;
}

@keyframes easeIn {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

:root {
    --primary-color: #fff;
    --secondary-color: #000;
    --tertiary-color: rgba(255, 0, 70, 1);
    --quaternary-color: rgba(40, 40, 50, 1);
    --quinary-color: rgba(10, 10, 20, 1);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

body,
#root,
html {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--primary-color);
}

.pageContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 1;
    padding-inline: 20px;
    /* overflow-y: scroll; */
}

header {
    width: 100%;
    border-bottom: 1px solid var(--underLine);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    backdrop-filter: blur(10px) saturate(120%);
    z-index: 2;
}

.headerInner {
    flex: 1;
    max-width: 1200px;
    height: 50px;
    margin-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 10px;
}

.logoContainer {
    height: 100%;
    display: flex;
}

.logo {
    height: 100%;
    /* color: var(--secondary-color); */
    color: rgba(255, 0, 70, 1);
    width: 30px;
    height: 100%;

}

.logoText {
    color: var(--secondary-color);
    font-family: "Inter";
    font-size: 1.5rem;
    width: 160px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #aaa; */
    font-weight: 550;
    letter-spacing: -1px;
    padding-right: 10px;
}

.subTitleText {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 20px;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
    position: absolute;
    padding-left: 240px;
    padding-top: 5px;
    color: var(--secondary-color);
}

.pageContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    margin-top: 50px;
    flex-direction: column;
    max-width: 1200px;
    top: 100px;
    animation: 1000ms ease 0s 1 normal backwards running easeIn;
    padding-bottom: 300px;
}

.videoInputForm {
    width: 100%;
    border: none;
}

.videoInputContainer {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoInput {
    width: 100%;
    height: 60px;
    border-radius: 6px;
    padding-left: 20px;
    font-size: 1rem;
    border: 2px solid rgba(255, 0, 70, 1);
    outline: none;
    display: flex;
    flex: 1;
    background-color: var(--primary-color);
    color: var(--secondary-color);
}

.videoInputLoadButton {
    height: 100%;
    width: 60px;
    border: none;
    background-color: rgba(255, 0, 70, 1);
    margin-left: 20px;
    border-radius: 8px;
}

.videoInputSvg {
    width: 30px;
    fill: #fff;
}

.navBar {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding-block: 32px;
}

.navBar svg:last-child {
    margin-right: 0;
}

.navLogo {
    height: 100%;
    margin-inline: 10px;
    cursor: pointer;
    fill: var(--secondary-color);
}

.statusContainer {
    width: 100%;
    height: 50px;
    background-color: rgba(255, 0, 70, 1);
    margin-block: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 5px;
    font-size: 1.25rem;
    font-weight: 500;
}

.previewContainer {
    width: 100%;
    display: flex;
    border-radius: 11px;
    width: 100%;
    max-width: 1200px;
    border-radius: 6px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: var(--primary-color);
    box-shadow: var(--boxShadow1);
    padding: 20px;
    max-height: 1200px;
    /* overflow-y: scroll; */
    border: 1px solid var(--underLine);
}


.previewHeader {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--underLine);

}

.previewThumbnailContainer {
    height: 100%;
}

.previewThumbnail {
    height: 100%;
    border-radius: 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 0px;
}

.previewInformation {
    flex: 1;
    height: 100%;
    padding: 10px;
    padding-right: 0px;
    padding-block: 10px;
    padding-top: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.previewImageContainer {
    width: 320px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.previewTitle {
    width: 100%;
    margin-bottom: 10px;
    font-size: 1.25rem;
    font-weight: 600;
    font-family: "Inter";
    color: var(--secondary-color);
}

.previewDescription {
    width: 100%;
    font-weight: 500;
    flex: 1;
    font-size: 0.85rem;
    color: var(--secondary-color);
    font-family: "Inter";
    margin-bottom: 10px;
}

.previewMetaData {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.previewMetaData>* {
    font-size: 0.9rem;
    font-weight: 400;
    font-family: "Inter";
    margin-right: 10px;
    color: var(--secondary-color);
}

.previewFunctionContainer {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: var(--primary-color);
}

.previewSummarizeButton,
.previewSummarizeButtonActive {
    flex: 1;
    height: 100%;
    border-radius: 4px;
    border: 1px solid var(--underLine);
    background-color: rgba(255, 0, 70, 1);
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Inter";
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s;
    position: relative;
    z-index: 0;
}

.previewSummarizeButtonChevron {
    height: 70%;
    position: absolute;
    right: 10px;
    color: #fff;
}

.previewSummarizeButton:hover {
    background-color: var(--primary-color);
    color: rgba(255, 0, 70, 1);
}

.previewSummarizeButton:hover>.previewSummarizeButtonChevron {
    right: 5px;
    color: rgba(255, 0, 70, 1);
}

.previewImage {
    width: 284px;
    height: 156px;
    border-radius: 8px;
}

.previewContentContainer {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.previewAuthor {
    color: var(--secondary-color);
}

.previewDuration {
    color: var(--secondary-color);
}

.functionsContainer {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 15px;
}

.summaryContainer {
    margin-bottom: 50px;
}

.summarizeButton {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 0, 70, 1);
    border-radius: 10px;
    border: none;
    height: 100%;
    padding-block: 10px;
    cursor: pointer;
}

.summarizeButtonText {
    font-size: 1.25rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.summarizeButtonLogo {
    height: 80%;
    fill: #fff;
}

.summarizeButtonLogoSpin {
    animation: spin 1s linear infinite;
    height: 80%;
    fill: #fff;
}

.summarizeButton:active {
    background-color: rgba(255, 0, 50, 1);
}

.chapterTitle {
    color: var(--secondary-color);
    font-size: 1.75rem;
}

.bulletPointsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-block: 15px;
    margin-bottom: 30px;
}

.bulletPointContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-block: 5px;
}

.bulletPointText {
    font-weight: 500;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    color: var(--secondary-color);
}

.bulletPoint {
    font-weight: 400;
    font-size: 1.5rem;
    color: var(--secondary-color);
}

.openAIKeysLink {
    width: 100%;
    height: 40px;
    background-color: rgba(255, 0, 70, 1);
    color: #fff;
    border: none;
    border-radius: 10px;
    margin-top: 100px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    color: var(--secondary-color);
}

.instructionsContainer {
    width: 100%;
    margin-block: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 40px;
    margin-top: 20px;
}

.instruction {
    font-size: 1.25rem;
    font-weight: 500;
    margin-block: 4px;
    width: 100%;
    padding-left: 5px;
    border-left: 3px solid var(--secondary-color);
    color: var(--secondary-color);
}

.disclaimer {
    color: var(--secondary-color);
}

.keyInputForm {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    background-color: var(--primary-color);
}

.keyInput {
    display: flex;
    flex: 1;
    height: 100%;
    border-radius: 6px;
    border: 2px solid var(--secondary-color);
    padding-left: 20px;
    color: var(--secondary-color);
    background-color: var(--primary-color);
}

.link {
    text-decoration: underline;
    color: var(--secondary-color);
}

.keySaveButton {
    height: 100%;
    width: 100px;
    margin-left: 10px;
    border-radius: 8px;
    background-color: rgba(255, 0, 70, 1);
    color: #fff;
    border: none;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 1px;
    cursor: pointer;
}

.keySaveButton:active {
    background-color: rgba(255, 0, 50, 1);
}

.themeContainer {
    width: 40px;
    height: 100%;
    position: relative;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}

.themeLogo {
    height: 100%;
    color: var(--secondary-color);
    cursor: pointer;
}

.resetApiKeyButton {
    width: 140px;
    height: 40px;
    background-color: var(--quaternary-color);
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 100%;
    font-weight: bold;
    cursor: pointer;
    color: var(--secondary-color);
    margin-right: 20px;
    border: 1px solid var(--underLine);
}

.resetApiKeyButton:hover {}

.languageDropdownContainer {
    position: relative;
}

.languageDropdown {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 100;
    position: absolute;
    left: -60px;
    overflow-y: auto;
    max-height: 300px;
    margin-top: 10px;
    background-color: var(--quinary-color);
}

.languageSelectButton {
    background-color: var(--quinary-color);
    border: none;
    border-bottom: 0.5px solid var(--secondary-color);
    width: 160px;
    height: 40px;
    color: var(--secondary-color);
}

.languageSelectButton:hover .languageSelectText {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.languageSelectText {
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 300;
    width: 100%;
    padding-block: 10px;
}

.languageButton {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    border: none;
    margin-right: 20px;
    background-color: var(--quaternary-color);
    cursor: pointer;
    font-size: 1.5rem;
}

.settingsContainer {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.temperatureInput {
    margin-right: 20px;
    height: 30px;
    background-color: var(--quaternary-color);
    border: none;
    color: var(--secondary-color);
    border-radius: 4px;
    text-align: center;
    text-justify: center;
    outline: none;
    z-index: 0;
}

.promptInput {
    margin-right: 20px;
    width: 400px;
    height: 30px;
    background-color: var(--quaternary-color);
    border: none;
    color: var(--secondary-color);
    border-radius: 4px;
    text-align: center;
    text-justify: center;
    outline: none;
    z-index: 0;
}

.modelSelector {
    margin-right: 20px;
    height: 30px;
    background-color: var(--quaternary-color);
    border: none;
    color: var(--secondary-color);
    border-radius: 4px;
    text-align: center;
    text-justify: center;
    outline: none;
}

.stopButton {
    width: 100px;
    height: 100%;
    border-radius: 10px;
    background-color: var(--quaternary-color);
    color: var(--secondary-color);
    border: none;
    font-size: 1rem;
    cursor: pointer;
}

.spacer {
    flex: 1;
    height: 100%;
}

.linksContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.linksContainer>svg {
    flex: 1;
    height: 100%;
    transition: fill 0.5s;
    margin-inline: 6px;
    cursor: pointer;
}

.linksContainer>svg:hover {
    fill: rgba(255, 0, 70, 1);
}

.heroContainer {
    /* margin-bottom: 60px; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 450px;
    margin-top: 100px;
}

.heroTitle {
    width: 60%;
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter";
    font-weight: 400;
    font-size: 60px;
    text-align: center;
}

.heroSubheading {
    width: 40%;
    color: var(--secondaryColorLighter);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter";
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
}

.heroButtons {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.getStartedButton {
    width: 200px;
    border: none;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    height: 40px;
    border-radius: 20px;
    font-size: 1rem;
    font-family: "Inter";
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    cursor: pointer;
    transition: 0.25s;
}

.getStartedButton>svg {
    transition: 0.25s;
}

.getStartedButton:hover {
    background-color: var(--primary-color);
    border: 1px solid rgba(255, 0, 70, 1);
    color: rgba(255, 0, 70, 1);
}

.getStartedButton:hover>svg {
    transform: translateX(10px);
    color: rgba(255, 0, 70, 1);
}

.showMeButton {
    width: 140px;
    border: none;
    background-color: transparent;
    color: var(--secondary-color);
    border-bottom: 1px solid var(--underLine);
    height: 40px;
    border-radius: 0px;
    font-size: 1rem;
    font-family: "Inter";
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    transition: background-color 0.5s, border 0.25s;
    cursor: pointer;
}

.showMeButton:hover {
    color: var(--hoverText);
}

.backgroundBlurHeader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(0.5px);
    background-color: var(--primary-color);
    opacity: 0.9;
    z-index: -1;
}

@media (max-width: 1060px) {
    .heroTitle {
        width: 75%;
        font-size: 56px;
    }

    .heroSubheading {
        width: 65%;
    }
}

@media (max-width: 820px) {
    .heroTitle {
        width: 80%;
        font-size: 50px;
    }

    .heroSubheading {
        width: 70%;
    }
}

@media (max-width: 700px) {
    .heroTitle {
        width: 90%;
        font-size: 48px;
    }

    .heroSubheading {
        width: 80%;
    }

    .previewDescription {
        font-size: 0.75rem;
    }

    .previewTitle {
        font-size: 1.15rem;
    }

    .featuresList {
        grid-template-columns: 1fr !important;
    }
}

@media (max-width: 600px) {
    .heroTitle {
        font-size: 42px;
    }

    .heroSubheading {
        width: 90%;
    }

    .previewHeader {
        flex-direction: column;
        justify-content: flex-start;
        height: 240px;
    }

    .previewContainer {
        padding: 4px;
    }

    .previewThumbnailContainer {
        width: 100%;
        height: fit-content !important;
        background-color: #000;
        height: 120px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .previewThumbnail {
        border-radius: 4px;
        width: 100%;
    }

    .container {
        padding: 20px !important;
    }

    .botMessageContainer {
        padding-inline: 20px;
    }

    .botLogo {
        position: absolute;
        opacity: 0;
    }
}

@media (max-width: 420px) {
    .heroTitle {
        width: 100%;
        font-size: 34px;
    }

    .heroSubheading {
        font-size: 16px;
    }
}

@media (max-width: 380px) {
    .heroTitle {
        width: 100%;
        font-size: 32px;
    }
}

@media (max-width: 300px) {
    .heroTitle {
        width: 100%;
        font-size: 24px;
    }

    .heroSubheading {
        width: 100%;
        font-size: 14px;
    }
}

.botMessageContainer {
    width: 100%;
    min-height: 80px;
    border-bottom: 1px solid var(--underLine);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 30px;
}

.botLogo {
    height: 100%;
}

.botLogo>svg>* {
    transform: scale(0.75);
    fill: var(--secondary-color) !important;
}

.botMessage {
    flex: 1;
    height: 100%;
    padding-left: 10px;
    padding-top: 0px;
}

.botMessage>* {
    color: var(--secondary-color);
    /* font-size: 100%; */
    /* font-weight: 400; */
}

.container {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: var(--secondary-color); */
    padding: 40px;
    flex-direction: column;
}

.previewSummarizeButtonActive {
    height: 40px;
}

.previewChat {
    transition: max-height 1s ease-in-out;
    width: 100%;
    overflow: scroll;
    max-height: 0;
}

.open {
    max-height: 1200px;
    /* or some other value that's bigger than your content's height */
}

.previewSummarizeButtonSpinner {
    margin-left: 10px;
    animation: 1s linear spin infinite;
    color: #fff;
}

.containerHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;
    /* background-color: #000; */
}

.previewContainerContainer {
    width: 100%;
    max-width: 1200px;
}

.containerHeaderTitle {
    padding-bottom: 6px;
    margin-bottom: 10px;
    border-bottom: 2px solid var(--underLine);
    font-family: "Inter";
    font-size: 2.5rem;
    color: var(--secondary-color);
}

.containerHeaderDescription {
    font-weight: 400;
    font-family: "Inter";
    color: var(--secondary-color);
    padding-top: 20px;
}

.previewSummarizeButtonDone {
    margin-left: 10px;
    color: #fff;
}

.featuresContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
}

@media (max-width: 1000px) {
    .previewTitle {
        font-size: 1.1rem;
    }
}

.featuresList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
}

.feature {
    border: 1px solid var(--underLine);
    padding: 24px;
    border-radius: 10px;
    box-shadow: var(--boxShadow1);
}

.featuresTitleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}

svg {
    color: var(--secondary-color);
}

.featureTitle {
    font-size: 1.25rem;
    padding-left: 10px;
    flex: 1;
    font-family: "Inter";
    color: var(--secondary-color);
}

.featureDescription {
    font-size: 1rem;
    font-weight: 400;
    font-family: "Inter";
    color: var(--secondary-color);
}

.gridStarters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    width: 100%;
    max-width: 1200px;
    box-shadow: var(--boxShadow1);
}

.gridStarter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--underLine);
    cursor: pointer;
}

.gridStarter:hover {
    background-color: var(--secondary-color);
}

.gridStarter:hover>svg {
    fill: var(--primary-color)
}

.gridStarter:hover>.gridStarterTitle {
    color: var(--primary-color)
}

.gridStarter>svg {
    width: 40px;
}

.gridStarterTitle {
    color: var(--secondary-color);
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Inter";
    margin-bottom: 20px;
    text-align: center;
}


.getStartedButton>svg {
    color: var(--primary-color);
}